import React, { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";


function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-7 w-7 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fbbd69"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}


const CommonQuestion = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <>
            <section className="tv-section8">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Some common phone questions</h2>
                    <div className="max-width1000">
                        <Fragment>
                            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(1)}>
                                    What are all the features included?
                                </AccordionHeader>
                                <AccordionBody>
                                    This whole list is free and included:

                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Title</th>
                                                <th>Description</th>
                                            </tr>
                                            <tr>
                                                <td>Voicemail</td>
                                                <td>A voicemail box was assigned to you with your service. In the Voice Portal, you can upload your voicemail greetings and personal name recordings, view and manage any saved messages, and set up voicemail forwarding to email.</td>
                                            </tr>
                                            <tr>
                                                <td>Caller ID </td>
                                                <td>The name sent with caller ID, sometimes referred to as CNAM, is a setting on your phone number. </td>
                                            </tr>
                                            <tr>
                                                <td>Call History</td>
                                                <td>Call History holds the records of all calls made and received on the account.</td>
                                            </tr>
                                            <tr>
                                                <td>Do Not Disturb </td>
                                                <td>When you don’t want your phone to ring, activate Do Not Disturb to send all incoming calls directly to your voicemail box.</td>
                                            </tr>
                                            <tr>
                                                <td>Last Call Return</td>
                                                <td>Three-way calling lets you bring two parties into the same call so all may participate in the same conversation.</td>
                                            </tr>
                                            <tr>
                                                <td>Voicemail Local Access</td>
                                                <td>Your voicemail box can be accessed in the Voice Portal, remotely, or by dialing a star code from a phone connected to the account.</td>
                                            </tr>
                                            <tr>
                                                <td>Three-way Calling</td>
                                                <td>Three-Way Calling lets you talk with two people in two different locations, at the same time, or talk privately with one party while the other holds.</td>
                                            </tr>
                                            <tr>
                                                <td>Call Handling</td>
                                                <td>Call Handling determines how your incoming calls will be routed under different circumstances.</td>
                                            </tr>
                                            <tr>
                                                <td>Call Screening</td>
                                                <td>You have control to ensure you receive important calls. You can limit the callers that ring your phone, filter callers, or forward important callers to another number.</td>
                                            </tr>
                                            <tr>
                                                <td>Anonymous Callers</td>
                                                <td>Calls from anonymous callers (those without caller ID) can waste your time and distract you from other calls you want to take. Block them from even ringing your phone.</td>
                                            </tr>
                                            <tr>
                                                <td>Custom Callers </td>
                                                <td>Use this feature to control who can call your phone and what happens when they do. These settings can be managed in the Voice Portal or by star code.</td>
                                            </tr>
                                            <tr>
                                                <td>Robocall Blocking</td>
                                                <td>Block the telephone numbers of known telemarketers and robocallers.</td>
                                            </tr>
                                            <tr>
                                                <td>Sim Ring</td>
                                                <td>Enables a call to ring to multiple lines and/or phone numbers simultaneously.</td>
                                            </tr>
                                            <tr>
                                                <td>Call Trace</td>
                                                <td>Call Trace allows you to mark a harassing or threatening phone call in your Call History.</td>
                                            </tr>
                                            <tr>
                                                <td>Call Forwarding</td>
                                                <td>When you aren’t available to answer calls on your phone, you can forward your calls somewhere else. Call Forwarding can be set up in the voice portal or by star codes. In the Voice Portal, you can set up a schedule to forward calls during certain hours, like your lunch break.</td>
                                            </tr>
                                            <tr>
                                                <td>Remote Access Forwarding</td>
                                                <td>When you’re away from your phone but still want to receive calls, you don’t need to get back to your phone to set things up. Unconditional call forwarding, or forwarding of all calls, is available remotely using the voicemail system, so it will continue to function even if there is a power outage, internet outage, or device failure.</td>
                                            </tr>
                                            <tr>
                                                <td>Call Waiting</td>
                                                <td> With Call Waiting, you can see who is calling, even if you’re on another call (as long as caller ID is supported on your phone). If a second call comes in while you’re on a call, you’ll hear the Call Waiting tone. To answer that call, you can choose to place your current call on hold and pick up the incoming call. Additionally, you can temporarily or completely enable or disable Call Waiting from your phone or in the Voice Portal.</td>
                                            </tr>
                                            <tr>
                                                <td>Collect Calls</td>
                                                <td> Collect calls are currently not offered.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(2)}>
                                    What would be long distance calling?
                                </AccordionHeader>
                                <AccordionBody>
                                    Call anywhere across Canada and the US (excluding Hawaii and Alaska) free of charge. For calling outside of Canada and the US we recommend you use an online service like Whatsapp or Skype to avoid expensive long distance charges. To keep our phone service at the lowest cost to you and to prevent any surprises we do not offer any long distance in our plans.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(3)}>
                                    Can I get a new number?
                                </AccordionHeader>
                                <AccordionBody>
                                    Yup. We can provide you a new number free of charge.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(4)}>
                                    Can I use my existing phone number?
                                </AccordionHeader>
                                <AccordionBody>
                                    Yup. You can keep your current phone number and we will take care of porting it over to our service for you.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(5)}>
                                    How long does it take to port my current home phone number?
                                </AccordionHeader>
                                <AccordionBody>
                                    Normally takes about a week. During this week you will not lose access to your current phone. Once it switches it is automatic and can then plug in your Purple Cow phone box.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(6)}>
                                    Is a phone handset included in this as well?
                                </AccordionHeader>
                                <AccordionBody>
                                    What we provide is a box for your handset to plug into. We do not supply the handset however the box will work with all existing phones.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 7} icon={<Icon id={7} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(7)}>
                                    What is the difference between VOIP and Landline?
                                </AccordionHeader>
                                <AccordionBody>
                                    VOIP (Voice over Internet Protocol) uses the internet to transmit voice data digitally, offering flexibility and cost-effectiveness for voice communication on various devices. It relies on internet connectivity, which may affect call quality. In contrast, Landline uses traditional copper wires for analog voice transmission, providing stable call quality and reliability, even during power outages. Landlines are fixed to specific geographical locations, while VOIP enables communication from any internet-connected place. VOIP typically offers advanced features like video conferencing, while Landlines may have limited features. The choice depends on factors such as cost, mobility, call quality, and the need for additional functionalities.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 8} icon={<Icon id={8} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(8)}>
                                    What are the star codes for voice mail, return missed call and others?
                                </AccordionHeader>
                                <AccordionBody>
                                <p>You can find the full list here: <a href="https://purplecowinternet.com/home-phone/star-codes/" style={{color: "purple"}}>STAR CODES</a></p>
                                </AccordionBody>
                            </Accordion>
                        </Fragment>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CommonQuestion
