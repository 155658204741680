import React from "react";
import { Link } from "gatsby"

const LastSection = (props) => {
    const { title} = props;
    return (
        
        <div className="container mx-auto">
            <section className="tv-section10">
                <h2 className="h2 text-white text-center">{title}</h2>
                <Link className="btn-bg-new mx-auto mt-8"  to="/join-the-herd">Join the herd</Link>
            </section>
        </div>
        )
}

export default LastSection