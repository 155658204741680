import React, { useEffect, useState } from "react";
import LayoutNew from "../components/LayoutNew";
import { Helmet } from "react-helmet";
import axios from "axios";
import {splitDescription} from "../utils";
import {productCatalogS3Bucket} from "../config"

import HaveQuestion from "./new-pages/common/HaveQuestion";
import LastSection from "./new-pages/common/LastSection";
import MessageModal from "./new-pages/common/MessageModal";

import bubbleIcon from "../../static/images/speech-bubble.png";
import CommonQuestionPhone from "./new-pages/common/CommonQuestionPhone";

const FrontPhonePage = () => {
    const [messagePopup, setMessagePopup] = useState(false);
    const [tabType, setTabType] = useState(false);
    const [phones, setPhones] = useState([{
        id: 0,
        api_name: "Loading...",
        description: "\u2714\ufe0fLoading...",
        billing_period: [
            {
                monthly: {
                    api_name: "Loading...",
                    display_name: "Home Phone",
                    invoice_name: "Home Phone",
                    price: 10.00
                }
            }
        ],
        addons: [],
        resources: [],
        status: "ACTIVE"
    }]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/home-phones/plans.json"
                );
                setPhones(response.data);
            } catch (err) {
                console.error("Failed to fetch phone plans:", err);
            }
        };
        fetchData();
    }, []);

    return (
        <LayoutNew>
            <Helmet>
                <title>Phone – Purple Cow Internet 💜🐄</title>
            </Helmet>
            <section className="tv-section4">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Home phone, keep your<br />
                        existing number</h2>
                    <p className="p2 text-black text-center mb-8">Home phone price can't be beat!</p>
                    <div className="selectPlan addon-plan-1">
                        {phones.map((phone, index) => (
                            phone.status != "ACTIVE" ? null : (
                                <div className="selectPlan__optional" key={index}>
                                    <label className="selectPlan__box phone-page">
                                        <input
                                            type="checkbox"
                                            name="selectPlan__"
                                            checked={false}
                                        />
                                        <div className="selectPlan__box-inner">
                                            <h3 className="h3">{phone.billing_period[0].monthly.display_name}</h3>
                                            <h2 className="h2"
                                                dangerouslySetInnerHTML={{__html: '$' + phone.billing_period[0].monthly.price + ' <sup>/ month</sup>' }}>
                                            </h2>
                                            <ul className="plan-list">
                                                {splitDescription(phone.description).map((line, index) => (
                                                    <li key={index}>{line}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </label>
                                </div>
                            )
                        ))}
                    </div>
                    <p className="p2 bottom">One time phone number port or new number charge of $55. Shipping included
                        free.<br/><br/></p>
                </div>
            </section>

            <CommonQuestionPhone/>
            <HaveQuestion closepopup={setMessagePopup}/>
            <LastSection title="Or if you're ready..."/>

            <div className="contact-us-parent">
                <div className="contact-click-box" onClick={() => setMessagePopup(true)}>
                    <img src={bubbleIcon} alt="bubble" className="bubble-icon"/>
                </div>
            </div>
            {messagePopup ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
        </LayoutNew>
    )
}

export default FrontPhonePage